import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import { Icon, Container, Divider, Form, Header, Message, Grid, Input, List, Loader } from 'semantic-ui-react';
import { TwitterIcon, TwitterShareButton } from "react-share";
import 'semantic-ui-css/semantic.min.css'
import './main.css'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            problems: [],
            probid: -1,
            showanswer: false,
            answer: '',
            history: [],
            starttime: 0,
            curtime: 0,
            tottime: 0,
        };

        this.interval = setInterval(() => {
            if(this.state.probid >= 0 && !this.state.showanswer){
                const curtime = Math.floor((Date.now() - this.state.starttime) / 1000);
                this.setState({
                    curtime: curtime,
                });     
            }       
        }, 100);

        this.query();
    }

    query() {
        axios.post(process.env.REACT_APP_PRETRAIN_BACKEND_URL)
        .then(response => {
            this.setState({
                problems: response.data.res,
            })
        }).catch(err => {
            console.log('Error');
        });
    }

    handleSubmit(){
        const probid = this.state.probid;
        if(this.state.problems.length === 0){
            return;
        }
        if(this.state.showanswer){
            this.setState({
                probid: probid + 1,
                showanswer: false,
                answer: '',
                tottime: this.state.tottime + this.state.curtime,
                curtime: 0,
                starttime: Date.now(),
            });
            if(probid + 1 === this.state.problems.length){
                const titles = [];
                for(let i = 0; i < this.state.problems.length; i++){
                    titles.push(this.state.problems[i][0]);
                }
                console.log(titles);
                this.setState({
                    titles: titles
                });
                this.query();
            }
            return;
        }
        if(probid === -1 || probid === this.state.problems.length){
            this.answerref.focus();
            this.setState({
                probid: 0,
                showanswer: false,
                history: [],
                answer: '',
                tottime: 0,
                curtime: 0,
                starttime: Date.now(),
            });
            return;
        }
        const problem = this.state.problems[probid];
        const correct = this.state.answer.toLowerCase() === problem[3].toLowerCase()
        const history = this.state.history.slice();
        history.push(correct);
        this.setState({
            correct: correct,
            showanswer: true,
            history: history,
            answer: '',
        });
    }

    render() {
        const probid = this.state.probid;
        const clock = <div className='time'><Icon name="hourglass outline" />{this.state.curtime} sec | {this.state.curtime + this.state.tottime} sec</div>;
        let statement = "";
        if(this.state.problems.length === 0){
            statement = <div>
                <div className="ui center aligned grid"><img src={`${process.env.PUBLIC_URL}/mensetsu_business_ai.png`} alt="top" className='topimg' /></div>
                <div className="ui center aligned grid"><div className='startbutton'><Loader active inline /></div></div>
            </div>;
        } else if(probid === -1){
            statement = <div>
                <div className="ui center aligned grid"><img src={`${process.env.PUBLIC_URL}/mensetsu_business_ai.png`} alt="top" className='topimg' /></div>
                <div className="ui center aligned grid"><div className='startbutton'><button className="ui primary button large" onClick={() => this.handleSubmit()}>Start (click or press enter)</button></div></div>
            </div>;
        } else if(probid === this.state.problems.length){
            let res_emoji = "";
            for(let i of this.state.history){
                if(i){
                    res_emoji += "✅";
                } else {
                    res_emoji += "❌";
                }
            }
            let correct = 0;
            for(let i of this.state.history){
                if(i){
                    correct += 1;
                }
            }
            correct = Math.floor(correct * 100 / this.state.history.length);
            statement = <div>
                <div className="ui center aligned grid"><span className="result">Results</span></div>
                <div className="ui center aligned grid"><span className="accuracy-res">Accuracy: {String(correct)} %</span></div>
                <div className="ui center aligned grid"><span className="time-res">Time: {this.state.tottime} sec</span></div>
                <div className="ui center aligned grid"><div className="tweet">
                <TwitterShareButton url='https://pretrain-yourself.joisino.net' title={res_emoji + "\nI got " + String(correct) + "% accuracy in " + String(this.state.tottime) + " sec in manual masked language modeling!"}>
                        <TwitterIcon round  />
                    </TwitterShareButton>
                </div>
                </div>
                <div className="paperList">
                    Paper List:
                    <List bulleted>
                    {this.state.titles.map((title) => (
                        <List.Item className="papers">{title}</List.Item>
                    ))}
                    </List>
                </div>
                <div><div className="ui center aligned grid"><button className="ui primary button large" onClick={() => this.handleSubmit()}>Retry (click or press enter)</button></div></div>
            </div>;
        } else if(this.state.showanswer){
            const problem = this.state.problems[probid];
            let classname;
            if(this.state.correct){
                classname = 'correcttoken';
            } else {
                classname = 'wrongtoken';
            }
            statement = <div>
                {clock}
                <span>{problem[1]}</span>
                <span className={classname}>{problem[3]}</span>
                <span>{problem[2]}</span>
                <div className="papertitle">{problem[0]}</div>
            </div>;
        } else {
            const problem = this.state.problems[probid];
            statement = <div>
                {clock}
                <span>{problem[1]}</span>
                <span className='masktoken'>[MASK]</span>
                <span>{problem[2]}</span>
                <div className="papertitle">{problem[0]}</div>
            </div>;
        }
        const grid = [];
        for(let i = 0; i < this.state.problems.length; i++){
            if(i < this.state.history.length){
                if(this.state.history[i]){
                    grid.push(<Grid.Column key={i}><div className='stategreen'> </div></Grid.Column>);
                } else {
                    grid.push(<Grid.Column key={i}><div className='statered'> </div></Grid.Column>);
                }

            } else {
                grid.push(<Grid.Column key={i}><div className='stategrey'> </div></Grid.Column>);
            }
        }
        return (
            <Container>
                <Divider hidden />
                <Header as='h1' dividing>
                    <Header.Content><a href="https://pretrain-yourself.joisino.net" style={{color: "black"}}>Pretrain Yourself</a></Header.Content>
                    <Header.Subheader>Pretrain the language model in your mind.</Header.Subheader>
                </Header>

                <div className="ui massive message">
                {statement}
                </div>

                <Form size='massive' onSubmit={() => {this.handleSubmit()}}>
                    <Input fluid ref={ref => this.answerref = ref} placeholder='Answer' value={this.state.answer} onChange={event => {this.setState({answer: event.currentTarget.value});}} autoFocus/>
                </Form>

                <Grid columns='equal' padded>
                    {grid}
                </Grid>

                <Message>
                    <p>
                        This service uses <a href='https://api.semanticscholar.org/corpus' target='_blank' rel='noopener noreferrer'>Semantic Scholar Open Research Corpus</a> under the <a href='https://opendatacommons.org/licenses/by/1.0/' target='_blank' rel='noopener noreferrer'>ODC-BY</a> license.
                    </p>
                    <i>Waleed Ammar et al. <a href='https://www.semanticscholar.org/paper/09e3cf5704bcb16e6657f6ceed70e93373a54618' target='_blank' rel='noopener noreferrer'>Construction of the Literature Graph in Semantic Scholar</a>. NAACL 2018.</i>
                </Message>
                    <div style={{textAlign: "right"}}>
                        Twitter (ja): <a href='https://twitter.com/joisino_'>@joisino_</a> / Twitter (en): <a href='https://twitter.com/joisino_en'>@joisino_en</a> / webpage: <a href='https://joisino.net'>https://joisino.net</a>
                    </div>
            </Container>
        )
    }
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
)